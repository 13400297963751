<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as WORKPLACE_TYPES } from './store'
import { ROUTES as WORKPLACE_ROUTES } from './router'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '../../../components/thux-list/ThuxListMixin'

import WorkplaceTableVue from './WorkplaceTable.vue'
import WorkplaceCommonFiltersVue from './WorkplaceCommonFilters.vue'
import WorkplaceEditVue from './WorkplaceEdit.vue'

export default {
  name: 'WorkplaceList',
  mixins: [ThuxListMixin],
  components: {
    'component-table': WorkplaceTableVue,
    'common-filters': WorkplaceCommonFiltersVue,
    'component-detail': WorkplaceEditVue,
    'component-edit': WorkplaceEditVue
  },
  data () {
    return {
      WORKPLACE_ROUTES,
      title: this.$t('Work place'),
      rolePerm: ['miscellaneous_workplace_list'],
      actionEnablePermission: ['miscellaneous_workplace_enable'],
      actionDisablePermission: ['miscellaneous_workplace_disable'],
      searchFields: [
        { key: 'filter__name__icontains', placeholder: this.$t('Name'), type: 'text', col: 6 },
        { key: 'filter__description__icontains', placeholder: this.$t('Description'), type: 'text', col: 6 }
      ]
    }
  },
  computed: {
    ...mapGetters({
      ...WORKPLACE_TYPES.GENERIC.miscellaneous.workplace.LIST.GETTERS
    }),
    showList () {
      return this.$route.name === this.WORKPLACE_ROUTES.WORKPLACE_LIST
    },
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return hasPerm('miscellaneous_workplace_create')
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: WORKPLACE_TYPES.GENERIC.miscellaneous.workplace.LIST.MUTATIONS.setSelectedList,
      setSelectAll: WORKPLACE_TYPES.GENERIC.miscellaneous.workplace.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...WORKPLACE_TYPES.GENERIC.miscellaneous.workplace.LIST.ACTIONS
    })
  }
}
</script>
